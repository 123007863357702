<template>
    <div>
        <div class="custom-alert brackets-wrap_ mb-50">
            <div class="bracket bracket-blue-tl"></div>
            <div class="bracket bracket-blue-tr"></div>
            <div class="bracket bracket-blue-bl"></div>
            <div class="bracket bracket-blue-br"></div>
            <h4 class="d-ib">
                    <span>
                        <svg style="width: 35px; height: 35px; color: #E5AD3EFF" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                          <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                          <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                        </svg>
                    </span>
                attention
            </h4>
            <p class="d-ib pl-10 after-header">{{ header }}</p>
            <p class="d-none d-sm-none d-md-block">
                <span class="right-arrow pl-5 js-arrow">
                    <span>></span><span>></span><span>></span>
                </span>
                {{ text }}
            </p>
        </div>
        <br>
    </div>
</template>

<script>

export default {
    name: 'CustomAlertComponent',
    props: {
        header: String,
        text: String
    },
    methods: {
        arrowAnimation() {
            let arrow = document.querySelector('.js-arrow');
            arrow.childNodes[0].classList.add('specColor')
            setTimeout(() => {
                arrow.childNodes[0].classList.remove('specColor')
                arrow.childNodes[1].classList.add('specColor')
            }, 100)
            setTimeout(() => {
                arrow.childNodes[1].classList.remove('specColor')
                arrow.childNodes[2].classList.add('specColor')
            }, 200)
            setTimeout(() => {
                arrow.childNodes[2].classList.remove('specColor')
            }, 300)
        }
    },
    mounted() {
        this.arrowAnimation()
        setInterval(() => {
            this.arrowAnimation()
        }, 3000)
    },
}
</script>

<style scoped>
</style>
