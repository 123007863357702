var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"styledLine",style:({
    borderWidth: _vm.getHeight,
    background: _vm.getBgColor,
    boxShadow: _vm.getBoxShadow,
    marginLeft: _vm.getMargin, marginRight: _vm.getMargin,
    borderStyle: _vm.getBorderStyle
})})
}
var staticRenderFns = []

export { render, staticRenderFns }