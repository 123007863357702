import { render, staticRenderFns } from "./LegendComponent.vue?vue&type=template&id=52ec08df&scoped=true"
import script from "./LegendComponent.vue?vue&type=script&lang=js"
export * from "./LegendComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ec08df",
  null
  
)

export default component.exports